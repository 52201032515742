import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useLocation } from "@gatsbyjs/reach-router"
import { TabsNav } from "@modules/ui/components/TabsNav"
import { SiteDetailsTab } from "../constants.js"
import { getPathToSiteDetailsTab } from "../utils"
import {
  FiGitBranch,
  FiGitPullRequest,
  FiEye,
  FiSettings,
  FiLayers,
} from "react-icons/fi"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails.js"
import { useFlags } from "@modules/featureFlags"
import { useTracker } from "@modules/analytics"
import BuildQueueButton from "@modules/site/details/components/BuildQueueButton"
import { viewBuildQueueTabCss } from "@modules/ui/stylesheets/buildQueue"
import { Badge } from "gatsby-interface"

const siteTabIcons = {
  [SiteDetailsTab.Settings]: FiSettings,
}
const additionalSiteTabIcons = {
  [SiteDetailsTab.Settings]: FiSettings,
  [SiteDetailsTab.Deploys]: FiGitBranch,
  [SiteDetailsTab.PullRequests]: FiGitPullRequest,
  [SiteDetailsTab.CmsPreview]: FiEye,
  [SiteDetailsTab.DataLayer]: FiLayers,
}

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

function SiteDetailsTabsNav({
  tabs,
  siteId,
  organizationId,
  onViewBuildQueueClick,
}) {
  const { trackButtonClicked } = useTracker()
  const { flags } = useFlags()
  const location = useLocation()

  const siteDetails = useLocalSiteDetailsForBuilds(siteId)
  const productionBranch = siteDetails?.branch

  const tabFromPath = location.pathname.match(
    /\/sites\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/([a-zA-z-]*)/
  )?.[1]

  const activeTab = tabs.find(({ tab }) => tab === tabFromPath)?.tab

  const NewBadge = (
    <Badge
      tone="SUCCESS"
      textVariant="DEFAULT"
      css={theme => ({
        marginLeft: theme.space[2],
        fontWeight: theme.fontWeights.semiBold,
      })}
    >
      New
    </Badge>
  )

  return (
    <TabsNav>
      <TabsNav.Tabs>
        {tabs.map(({ tab, label, isNew }) => {
          const isActiveTab = tab === activeTab
          const Icon = flags.updatedCloudNavigation
            ? additionalSiteTabIcons[tab]
            : siteTabIcons[tab]

          if (flags.updatedCloudNavigation && tab === SiteDetailsTab.Deploys) {
            label = productionBranch ? productionBranch : label
          }

          const TabIcon = Icon ? <Icon css={TabsNav.leftIconStyle} /> : null

          return (
            <TabsNav.Tab key={tab}>
              {isActiveTab ? (
                <TabsNav.NoLink data-cy={`site-details-${tab}-tab-no-link`}>
                  {TabIcon}
                  {label}
                  {isNew && NewBadge}
                </TabsNav.NoLink>
              ) : (
                <TabsNav.Link
                  data-cy={`site-details-${tab}-tab-button`}
                  to={getPathToSiteDetailsTab(tab, siteId, organizationId)}
                  onClick={() => {
                    trackButtonClicked(label, {
                      siteId,
                      uiSource: `Site Settings`,
                    })
                  }}
                >
                  {TabIcon}
                  <TabsNav.Label label={label}>{label}</TabsNav.Label>
                  {isNew && NewBadge}
                </TabsNav.Link>
              )}
              {isActiveTab && (
                <Helmet>
                  <title>{label}</title>
                </Helmet>
              )}
            </TabsNav.Tab>
          )
        })}

        <li css={viewBuildQueueTabCss}>
          <BuildQueueButton
            onClick={onViewBuildQueueClick}
            source={`Site nav (tabs)`}
          />
        </li>
      </TabsNav.Tabs>
    </TabsNav>
  )
}

SiteDetailsTabsNav.propTypes = propTypes

export default SiteDetailsTabsNav
