import * as React from "react"
import { useSiteDetailsFieldsFragment } from "@modules/site/shared/fragments.generated"
import { DataLayer } from "../../dataLayer"
import useOrganizationNameAndTier from "../../../organization/shared/hooks/useOrganizationName"

export type DataLayerPaneProps = {
  organizationId: string
  siteId: string
  location: string
}
export function DataLayerPane({
  organizationId,
  siteId,
  location,
}: DataLayerPaneProps) {
  const { data: siteDetails } = useSiteDetailsFieldsFragment(siteId)
  const { orgName } = useOrganizationNameAndTier(organizationId)

  return siteDetails ? (
    <DataLayer
      organizationId={organizationId}
      siteId={siteId}
      location={location}
      orgName={orgName}
      siteName={siteDetails.publicName || siteDetails.name}
      buildsEnabled={siteDetails.buildsEnabled || false}
    />
  ) : null
}
