import * as React from "react"
import { HostingDomain } from "@gatsby-cloud-pkg/lib-graphql"
import { ThemeCss, Link, Tooltip } from "gatsby-interface"
import { Link as GatsbyLink } from "gatsby"
import { MdWarning, MdOpenInNew } from "react-icons/md"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { interpolateMessage } from "@modules/locales"
import { sites as sitesText } from "@modules/locales/default.js"

const domainsWrapperCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  gap: theme.space[3],
  [theme.mediaQueries.tablet]: {
    flexDirection: `row`,
    alignItems: `center`,
  },
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  textDecoration: `none`,
  color: theme.colors.grey[50],
  display: `flex`,
  alignItems: `center`,
  whiteSpace: `nowrap`,

  svg: {
    fontSize: theme.fontSizes[1],
    color: theme.colors.orange[60],
    marginRight: theme.space[1],
  },
})

const validLinkCss: ThemeCss = theme => [
  linkCss(theme),
  {
    fontWeight: theme.fontWeights.bold,
    color: theme.colors.purple[60],
    svg: {
      color: theme.colors.grey[30],
      fontSize: theme.fontSizes[2],
      margin: `0 0 0 ${theme.space[2]}`,
    },
  },
]
const remainingDomainsLinkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  textDecoration: `none`,
  whiteSpace: `nowrap`,
  ":hover": {
    textDecoration: `underline`,
  },
})

export type PrimaryDomainProps = {
  domains?: Array<HostingDomain>
  organizationId: string
  siteId: string
}

export default function PrimaryDomain({
  domains,
  siteId,
  organizationId,
}: PrimaryDomainProps) {
  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )

  if (domains && domains.length > 0) {
    const domain = domains[0]
    const { id, verified } = domain
    const remainDomainsCount = domains.length - 1

    let renderedLink

    if (verified) {
      renderedLink = (
        <Link href={`https://${id}`} css={validLinkCss} target="_blank">
          {id}
          <MdOpenInNew />
        </Link>
      )
    } else {
      renderedLink = (
        <Tooltip
          label={interpolateMessage<"domain">(
            sitesText.messages.domainIsNotValidated,
            { domain: id }
          )}
        >
          <GatsbyLink to={`${linkToSiteSettings}/hosting`} css={linkCss}>
            <MdWarning />
            {id}
          </GatsbyLink>
        </Tooltip>
      )
    }

    return (
      <div css={domainsWrapperCss}>
        {renderedLink}
        {remainDomainsCount > 0 && (
          <GatsbyLink
            to={`${linkToSiteSettings}/hosting`}
            css={remainingDomainsLinkCss}
          >
            {`+${remainDomainsCount} more domain${
              remainDomainsCount > 1 ? "s" : ""
            }`}
          </GatsbyLink>
        )}
      </div>
    )
  }
  return null
}
