import * as React from "react"
import { Link, Notification } from "gatsby-interface"
import { MdArrowForward, MdWarning } from "react-icons/md"
import {
  sites as sitesText,
  siteDetails as siteDetailsText,
} from "@modules/locales/default.js"
import { FormattedMessage, commonMessageTags } from "@modules/locales"

type LegacyPreviewBannerProps = {
  visible: boolean
}

export function LegacyPreviewBanner({ visible }: LegacyPreviewBannerProps) {
  if (!visible) {
    return null
  }
  return (
    <Notification
      Icon={MdWarning}
      variant="SECONDARY"
      isOpened={true}
      content={
        <React.Fragment>
          <span>
            <FormattedMessage<never, "strong">
              message={siteDetailsText.messages.legacyPreviewRequiredAction}
              tags={commonMessageTags}
            />
          </span>
          &nbsp;{" "}
          <Link
            variant="SIMPLE"
            to="https://support.gatsbyjs.com/hc/en-us/articles/6884375687059"
            css={{ whiteSpace: `nowrap` }}
          >
            {sitesText.actions.learnMore} <MdArrowForward />{" "}
          </Link>
        </React.Fragment>
      }
      tone="WARNING"
      data-testid="legacyPreviewBanner"
    />
  )
}
