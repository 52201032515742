import * as React from "react"
import { PageWithTabsContentSection } from "@modules/ui/layouts/Containers"
import {
  SidebarColumn,
  Content,
  InnerLayout,
} from "@modules/ui/layouts/ColumnLayout"
import { useDataLayerNavigation } from "../utils/helper"
// import { useSitePermissionsFieldsFragment } from "@modules/site/shared/fragments.generated"
import { DataLayerSection } from "../constants"
import {
  SettingsCard,
  SettingsCardContent,
  SettingsCardTitle,
} from "@modules/site/settings"
import { Fragment } from "react"
import { useSitePermissionsFieldsFragment } from "@modules/site/shared/fragments.generated"
import { DataLayerOverview } from "./DataLayerOverview"
import { Spacer, ThemeCss } from "gatsby-interface"
import WebhookSection from "@modules/site/settings/components/WebhookSection"
import { SiteSettingsSection } from "@modules/site/settings/constants"
import ContentLoaderSection from "@modules/site/settings/components/ContentLoaderSection"

export type DataLayerProps = {
  organizationId: string
  siteId: string
  location: string
  orgName: string
  siteName: string
  buildsEnabled: boolean
}

export type SectionProps = {
  active: boolean
  label: React.ReactFragment
  name: string
  subsections: SectionProps
  to: string
}

const headerCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  flexBasis: `100%`,
  fontSize: theme.fontSizes[4],
})

export function DataLayer({
  siteId,
  organizationId,
  location,
  orgName,
  siteName,
  buildsEnabled,
}: DataLayerProps) {
  const { data: { permissions } = {} } = useSitePermissionsFieldsFragment(
    siteId
  )

  const { sections, sidebarNavOptions } = useDataLayerNavigation({
    organizationId,
    siteId,
    location,
  })

  return (
    <PageWithTabsContentSection>
      <InnerLayout>
        <SidebarColumn options={sidebarNavOptions} />
        <Content>
          {sections
            .filter((item: SectionProps) => item.active)
            .map((item: SectionProps) => {
              const name = item.name
              let settingsSection = null
              switch (name) {
                case DataLayerSection.Overview:
                  settingsSection = (
                    <DataLayerOverview
                      siteId={siteId}
                      orgSiteName={`${orgName}/${siteName}`}
                      permissionToEdit={permissions?.sites?.edit || false}
                    />
                  )
                  break
                case DataLayerSection.Webhooks:
                  settingsSection = (
                    <Fragment>
                      <SettingsCardTitle>
                        <Spacer direction="horizontal" size={2} />
                        Webhooks
                      </SettingsCardTitle>
                      <WebhookSection
                        siteId={siteId}
                        type={SiteSettingsSection.PreviewWebhook}
                      />
                      {buildsEnabled && (
                        <WebhookSection
                          siteId={siteId}
                          type={SiteSettingsSection.BuildsWebhook}
                        />
                      )}
                      <ContentLoaderSection siteId={siteId} />
                    </Fragment>
                  )
                  break
                default:
                  settingsSection = null
              }

              return <Fragment key={name}>{settingsSection}</Fragment>
            })}
        </Content>
      </InnerLayout>
    </PageWithTabsContentSection>
  )
}
