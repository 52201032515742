import * as React from "react"
import Helmet from "react-helmet"
import { navigate } from "@gatsbyjs/reach-router"
import Loading from "@modules/ui/components/Loading"
import SiteDetailsTabsNav from "@modules/site/details/components/SiteDetailsTabsNav"
import {
  OrganizationStatus,
  CdnVendor,
  PlatformLimitSummaryName,
  CmsProvider,
  PlatformLimitSummary,
} from "@modules/graphql/types"
import {
  SiteInformationViewLayout,
  SiteInformationViewGrid,
  SiteHostingLimitsWrapper,
  SiteInformationViewGridWrapper,
  SiteInformationViewSiteNameArea,
  SiteInformationViewDescriptionArea,
  SiteInformationViewHeader,
  SiteInformationViewDetailsArea,
} from "../layouts"
import { CmsChip } from "@modules/site/informationView/components/InformationViewChips"
import OrganizationStatusNotification from "@modules/organization/shared/components/OrganizationStatusNotification"
import { SiteDescription } from "@modules/site/informationView/components/SiteDescription"
import { SiteDirectoryPath } from "@modules/site/informationView/components/SiteDirectoryPath"
import { TabRouter } from "@modules/site/informationView/components/TabRouter"
import { useSiteDetails } from "../hooks/useSiteDetails"
import { getSiteDetailsTabs } from "@modules/site/details/utils"
import { useFlags } from "@modules/featureFlags"
import { hasSourcePullRequestPermission } from "@modules/site/shared/helpers"
import { PullRequestPermissionBanner } from "./PullRequestPermissionBanner"
import { DetailsViewHeader } from "@modules/ui/components/DetailsViewHeader"
import { sites as sitesText } from "@modules/locales/default.js"
import { RepositoryChip } from "@modules/site/shared/components/RepositoryChip"
import { SourceControlProvider } from "@modules/graphql/types"
import { MdLockOutline } from "react-icons/md"
import {
  EmptyState,
  Spacer,
  EmptyStatePrimaryAction,
  Badge,
  ThemeCss,
} from "gatsby-interface"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { getPathToOrgSites } from "@modules/organization/shared/utils"
import { CdnIntegrationChip } from "@modules/cdn/shared/components/CdnIntegrationChip"
import { SitePermissions } from "@modules/site/permissions"
import { useHostingDetailsForSiteQuery } from "@modules/cdn/gatsbyCloudCdn/queries.generated"
import { HostingBanner } from "./HostingBanner"
import { useSitePlatformLimitSummariesQuery } from "@modules/site/queries.generated"
import { getPlatformLimitOveragesMessage } from "@modules/organization/shared/utils/getPlatformLimitOveragesMessage"
import { useFilterOutNotTrackedPlatformLimit } from "@modules/organization/shared/hooks/usefilterOutNotTrackedPlatformLimit"
import { PlatformLimitOverageNotification } from "@modules/organization/shared/components/PlatformLimitOverageNotification"
import { getPathToOrgPricing } from "@modules/organization/shared/utils"
import { isProperlyConnected } from "@modules/site/cdnIntegrations"
import { StatusPageBanner } from "@modules/status/components/StatusPageBanner"
import { BannersContainer } from "@modules/ui/components/BannersContainer"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import useNetPromoterSurvey from "@modules/netPromoterSurvey/useNetPromoterSurvey"
import { useCurrentUser } from "@modules/auth"
import { useWorkspaceBuildQueue } from "@modules/organization/builds/hooks/useWorkspaceBuildQueue"
import { WorkspaceBuildQueue } from "@modules/organization/builds/components/WorkspaceBuildQueue"
import { SiteHostingLimitsInfo } from "@modules/site/informationView/components/SiteHostingLimitsInfo"
import PrimaryDomain from "./PrimaryDomain"
import { LegacyPreviewBanner } from "@modules/status/components/LegacyPreviewBanner"
import { useGetSiteTransferInvitationQuery } from "@modules/site/queries.generated"
import { DisabledDataBuildsBanner } from "./DisabledDataBuildsBanner"

export const hostingInfoCss: ThemeCss = theme => ({
  marginBottom: theme.space[4],
})

type SiteInformationViewProps = {
  organizationId: string
  siteId: string
}

export function SiteInformationView({
  siteId,
  organizationId,
  children,
}: SiteInformationViewProps) {
  const siteDetails = useSiteDetails(siteId)
  const cdnIntegrations = siteDetails?.cdnIntegrations ?? []
  const activeIntegration = cdnIntegrations.find(isProperlyConnected)
  const gatsbyHostingOn = activeIntegration?.vendor === CdnVendor.CloudCdn
  const pathToBillingForm = getPathToOrgPricing(organizationId)
  const siteTransferred = siteDetails?.parentOrganizationId

  const { flags } = useFlags()

  const { data: hostingDetails } = useHostingDetailsForSiteQuery({
    variables: {
      siteId,
    },
    fetchPolicy: `cache-and-network`,
    skip: !siteDetails?.id || !gatsbyHostingOn,
  })

  const { data: limitSummariesData } = useSitePlatformLimitSummariesQuery({
    variables: {
      workspaceId: organizationId,
      siteId,
    },
    fetchPolicy: "cache-and-network",
    skip: !siteDetails?.id,
  })

  const { data: organizationData } = useOrganizationBaseDetailsQuery({
    variables: {
      id: organizationId,
    },
    skip: !siteDetails?.id,
  })

  const { data: siteTransferInvitationData } =
    useGetSiteTransferInvitationQuery({
      variables: {
        id: siteId,
      },
      skip: !siteDetails?.id,
    })

  const siteTransferPending =
    !siteDetails?.parentOrganizationId &&
    siteTransferInvitationData?.getSiteTransferInvitation?.id &&
    !siteTransferInvitationData?.getSiteTransferInvitation?.accepted

  const { currentUser } = useCurrentUser()
  useNetPromoterSurvey(organizationData?.organizationDetails, currentUser)

  const filterOutNotTrackedPlatformLimit =
    useFilterOutNotTrackedPlatformLimit(organizationId)

  const platformLimitSummaries = (
    limitSummariesData?.platformLimitSummaries || []
  ).filter(Boolean) as PlatformLimitSummary[]

  const platformLimitWidgetWhitelist = flags.pricingFall2022
    ? [PlatformLimitSummaryName.Bandwidth, PlatformLimitSummaryName.Invocations]
    : [
        PlatformLimitSummaryName.Bandwidth,
        PlatformLimitSummaryName.Requests,
        PlatformLimitSummaryName.Invocations,
      ]

  const platformLimitSummariesForWidgets = platformLimitSummaries.filter(item =>
    platformLimitWidgetWhitelist.includes(item.name as PlatformLimitSummaryName)
  )

  const platformLimitSummariesForMessage = platformLimitSummaries.filter(item =>
    filterOutNotTrackedPlatformLimit(item.name)
  )

  const platformLimitOveragesMessage = getPlatformLimitOveragesMessage(
    platformLimitSummariesForMessage
  )

  const hostingDetailsForSite = hostingDetails?.hostingDetailsForSite?.[0]

  // gets everything required to provide the Workspace build queue functionality
  const buildQueue = useWorkspaceBuildQueue(organizationId)

  const tabs = getSiteDetailsTabs(flags, {
    buildsEnabled: siteDetails?.buildsEnabled,
    permissions: siteDetails?.permissions,
  })

  if (siteDetails?.error?.message) {
    const pathToOrgSites = getPathToOrgSites(organizationId)
    if (
      siteDetails?.error?.message.includes(
        `You do not have permissions to view this Site`
      )
    ) {
      return (
        <StandardSingleColumn>
          <Spacer size={12} />

          <EmptyState
            graphic={<MdLockOutline />}
            variant="BORDERED"
            heading={sitesText.headers.noAccess}
            headingAs="h3"
            text={sitesText.messages.contactTheOwner}
            primaryAction={
              <EmptyStatePrimaryAction to={pathToOrgSites}>
                {sitesText.actions.backToHomepage}
              </EmptyStatePrimaryAction>
            }
          />
        </StandardSingleColumn>
      )
    }

    navigate(pathToOrgSites)
    return null
  }

  if (siteDetails.loading) {
    return <Loading delay={500} data-testid="information-view-loading" />
  }

  const hasPullRequestPermissions = hasSourcePullRequestPermission(
    siteDetails?.sourceOrganization?.permissions || []
  )

  const siteDescriptionElement = (
    <SiteDescription siteId={siteId} organizationId={organizationId}>
      {siteDetails.siteDescription}
    </SiteDescription>
  )

  const siteRepositoryElement = siteDetails.repository && (
    <RepositoryChip
      // Fallback to Github until repository.provider is properly implemented
      provider={siteDetails.repository.provider || SourceControlProvider.Github}
      repositoryUrl={siteDetails.repository.url}
      data-testid="information-view-fullname"
      css={theme => ({
        marginRight: theme.space[3],
      })}
    />
  )

  const siteCmsElement = siteDetails.cmsIntegrations?.length > 0 && (
    <div data-testid="information-view-cms-integrations">
      {siteDetails.cmsIntegrations.map(
        (provider: CmsProvider & { config?: { managementUrl?: string } }) => (
          <CmsChip
            key={provider.id}
            vendor={provider.name}
            formattedName={provider.formattedName}
            url={provider.config?.managementUrl}
          />
        )
      )}
    </div>
  )

  const siteDirectoryElement = siteDetails.siteDirectoryPath ? (
    <div
      css={theme => ({
        minHeight: theme.space[7],
        display: `flex`,
        alignItems: `center`,
      })}
    >
      <SiteDirectoryPath path={siteDetails.siteDirectoryPath} />
      <Spacer size={3} direction="horizontal" />
    </div>
  ) : null

  const siteName = siteDetails.site?.publicName || siteDetails.site?.name

  const domains = hostingDetailsForSite?.domains
    ? [...hostingDetailsForSite.domains]
        // make the last added domains first
        .reverse()
        // make unverified domains last
        .sort((a, b) => {
          if (a.verified && !b.verified) {
            return -1
          }
          if (!a.verified && b.verified) {
            return 1
          }
          return 0
        })
    : []

  return (
    <main>
      {siteName && (
        <Helmet
          titleTemplate={`Gatsby Cloud | ${siteName} | %s`}
          defaultTitle={`Gatsby Cloud | ${siteName}`}
        />
      )}
      <SiteInformationViewLayout>
        <SiteInformationViewHeader>
          <BannersContainer>
            <StatusPageBanner />
            <LegacyPreviewBanner
              visible={Boolean(
                siteDetails.previewBuildsEnabled &&
                  !siteDetails.incrementalPreviewsEnabled
              )}
            />

            {!hasPullRequestPermissions && (
              <SitePermissions id={siteId} resource="sites" action="edit">
                <PullRequestPermissionBanner
                  permissionsUrl={
                    siteDetails?.sourceOrganization?.permissionsURL || ""
                  }
                />
              </SitePermissions>
            )}

            {siteDetails.orgStatus !== OrganizationStatus.Active && (
              <OrganizationStatusNotification
                organizationId={organizationId}
                status={siteDetails.orgStatus}
              />
            )}

            <HostingBanner
              siteId={siteId}
              organizationId={organizationId}
              gatsbyHostingOn={gatsbyHostingOn}
              hostingDetails={hostingDetailsForSite}
            />

            {platformLimitOveragesMessage && (
              <PlatformLimitOverageNotification
                message={platformLimitOveragesMessage}
                tier={
                  organizationData?.organizationDetails?.billing?.plan
                    ?.baseFeatures?.tier
                }
                link={pathToBillingForm}
              />
            )}

            {flags.disabledDataBuilds && !siteDetails.dataBuildsEnabled && (
              <DisabledDataBuildsBanner />
            )}
          </BannersContainer>
          <SiteInformationViewGridWrapper>
            <SiteInformationViewGrid>
              <SiteInformationViewSiteNameArea>
                <DetailsViewHeader eyebrow={sitesText.morphemes.site}>
                  {siteName}
                </DetailsViewHeader>
              </SiteInformationViewSiteNameArea>
              <SiteInformationViewDescriptionArea>
                {siteDescriptionElement}
              </SiteInformationViewDescriptionArea>
              <SiteInformationViewDetailsArea>
                <div
                  css={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    alignItems: `center`,
                  }}
                >
                  {siteRepositoryElement}
                  {siteCmsElement}
                  {activeIntegration?.vendor && (
                    <React.Fragment>
                      <CdnIntegrationChip
                        vendor={activeIntegration.vendor}
                        siteId={siteId}
                        organizationId={organizationId}
                      />
                      <Spacer size={3} direction="horizontal" />
                    </React.Fragment>
                  )}

                  {siteDirectoryElement}

                  {siteTransferPending && (
                    <Badge tone="WARNING" textVariant="DEFAULT">
                      {sitesText.labels.transferPending}
                    </Badge>
                  )}

                  {siteTransferred && (
                    <Badge tone="NEUTRAL" textVariant="DEFAULT">
                      {sitesText.labels.siteTransferred}
                    </Badge>
                  )}
                </div>
              </SiteInformationViewDetailsArea>
              <PrimaryDomain
                organizationId={organizationId}
                siteId={siteId}
                domains={domains}
              />
            </SiteInformationViewGrid>
            <SiteHostingLimitsWrapper>
              {platformLimitSummaries && (
                <SiteHostingLimitsInfo
                  platformLimitSummaries={platformLimitSummariesForWidgets}
                />
              )}
            </SiteHostingLimitsWrapper>
          </SiteInformationViewGridWrapper>
        </SiteInformationViewHeader>

        <SiteDetailsTabsNav
          organizationId={organizationId}
          siteId={siteId}
          tabs={tabs}
          onViewBuildQueueClick={buildQueue?.onOpen}
        />

        <TabRouter
          siteId={siteId}
          organizationId={organizationId}
          tabs={tabs}
          onViewBuildQueueClick={buildQueue?.onOpen}
        />
        {/* support nested routes in reach router */}
        {children}

        <WorkspaceBuildQueue
          organizationId={organizationId}
          isOpen={buildQueue.isOpen}
          onClose={buildQueue.onClose}
          originId={buildQueue.originId}
        />
      </SiteInformationViewLayout>
    </main>
  )
}
