import React from "react"
import { useSiteDetailsFieldsFragment } from "@modules/site/shared/fragments.generated"
import useOrganizationNameAndTier from "../../../organization/shared/hooks/useOrganizationName"
import { SiteSettings } from "../../settings"

export default function SiteSettingsPane({
  organizationId,
  siteId,
  path,
  location,
}) {
  const { data: siteDetails } = useSiteDetailsFieldsFragment(siteId)
  const { orgName, tier } = useOrganizationNameAndTier(organizationId)

  return siteDetails ? (
    <SiteSettings
      path={path}
      organizationId={organizationId}
      siteId={siteId}
      siteName={siteDetails.publicName || siteDetails.name}
      productionBranch={siteDetails.branch}
      buildsEnabled={siteDetails.buildsEnabled}
      sitePreviewPassword={siteDetails.previewPassword}
      sitePreviewProtection={siteDetails.previewProtection}
      sitePreviewDirectory={siteDetails.previewDirectory}
      orgName={orgName}
      tier={tier}
      location={location}
      siteDescription={siteDetails.description}
      repository={siteDetails.repository}
      sitePrefix={siteDetails.sitePrefix || ""}
    />
  ) : null
}
