import * as React from "react"
import {
  Heading,
  ThemeCss,
  Link,
  Spacer,
  Badge,
  Tooltip,
  EmptyState,
} from "gatsby-interface"
import { Site, Build, CdnVendor } from "@modules/graphql/types"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import { isProperlyConnected } from "@modules/site/cdnIntegrations"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { MdSettings } from "react-icons/md"
import {
  getSiteDetailsTabBasePath,
  getPathToBuildsForBranch,
} from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import {
  deploysView as deploysViewText,
  ui as uiText,
} from "@modules/locales/default.js"
import { SiteBuildsLastBuild } from "./SiteBuildsLastBuild"
import { SiteBuildsPublishedBuild } from "./SiteBuildsPublishedBuild"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { descriptionCss } from "@modules/site/shared/stylesheets/siteBuildsBuildSection"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { ProductionBuildsList } from "./ProductionBuilds"
import { useBuilds } from "../hooks/useDeploys"
import { useFlags } from "@modules/featureFlags"
import { EmptyStateGraphicAlternative } from "@modules/ui/components/EmptyStateGraphicAlternative"
import {
  LastSuccessBuildMessage,
  AutomaticDeploysStatus,
} from "./SiteBuildsProduction.parts"

export type SiteBuildsProductionProps = {
  organizationId: string
  siteId: string
  siteData: Site
  lastBuild: Build
  repositoryUrl: string
  isEligiblePlan: boolean
  refetchSiteDetails?: () => void
  onViewBuildQueue?: (id: string) => void
}

export function SiteBuildsProduction({
  siteId,
  organizationId,
  siteData,
  lastBuild,
  repositoryUrl,
  isEligiblePlan,
  refetchSiteDetails,
  onViewBuildQueue,
}: SiteBuildsProductionProps) {
  const { flags } = useFlags()
  const [
    triggerBuildError,
    setTriggerBuildError,
  ] = React.useState<JSX.Element | null>(null)
  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )
  const cdnIntegrations = siteData?.cdnIntegrations ?? []
  const latestHostingDeployVersion = siteData?.latestHostingDeployVersion
  const manualHostingDeploysEnabled = siteData?.manualHostingDeploysEnabled
  const activeIntegration = cdnIntegrations.find(isProperlyConnected)
  const gatsbyHostingOn = activeIntegration?.vendor === CdnVendor.CloudCdn
  const productionBranch = siteData.branch

  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )

  const linkToHistory = getPathToBuildsForBranch(
    productionBranch,
    siteId,
    organizationId
  )

  const [builds] = useBuilds(
    siteId,
    productionBranch,
    !latestHostingDeployVersion
  )

  const publishedBuildPosition = builds.findIndex(
    item => item?.id === latestHostingDeployVersion
  )
  const lastBuildPosition = builds.findIndex(item => item?.id === lastBuild?.id)
  const canSeePublishedBuilds = gatsbyHostingOn && isEligiblePlan

  return (
    <React.Fragment>
      <div>
        {flags.updatedCloudNavigation ? (
          <React.Fragment>
            {gatsbyHostingOn && isEligiblePlan && (
              <div css={topCss}>
                <div css={buildMetaCss}>
                  <Heading as="h2" css={headingCss}>
                    {deploysViewText.headers.lastPublishedBuild}
                  </Heading>
                  {siteData?.stableBuildURL && (
                    <ExternalLink
                      href={siteData?.stableBuildURL}
                      truncate
                      size={2}
                    />
                  )}
                </div>

                <div css={buildActionsCss}>
                  {gatsbyHostingOn && isEligiblePlan && (
                    <AutomaticDeploysStatus
                      siteId={siteId}
                      organizationId={organizationId}
                      enabled={!manualHostingDeploysEnabled}
                    />
                  )}
                  <TriggerBuild
                    siteId={siteId}
                    selectedBranch={productionBranch}
                    orgStatus={siteData.organization?.status}
                    size={`M`}
                    triggerBuildError={triggerBuildError}
                    setTriggerBuildError={setTriggerBuildError}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Heading as="h2" css={headingCss}>
              {deploysViewText.headers.production}
            </Heading>
            <div css={topCss}>
              <div css={siteMeta}>
                <SiteBranch>
                  <strong>{productionBranch}</strong>
                </SiteBranch>

                {(!gatsbyHostingOn || !isEligiblePlan) &&
                  lastBuild?.endedAt && (
                    <BuildPublishedDate
                      buildPublishedDate={lastBuild.endedAt}
                    />
                  )}

                {gatsbyHostingOn && isEligiblePlan && (
                  <span css={deploysCss}>
                    <GatsbyCloudLogo />{" "}
                    {deploysViewText.labels.automaticDeploys}:
                    <Spacer size={3} direction="horizontal" />
                    <Badge
                      tone={
                        siteData?.manualHostingDeploysEnabled
                          ? `BRAND`
                          : `SUCCESS`
                      }
                    >
                      {siteData?.manualHostingDeploysEnabled
                        ? uiText.labels.disabled
                        : uiText.labels.enabled}
                    </Badge>
                    <Tooltip label={uiText.actions.editSettings}>
                      <span css={settingsLinkCss}>
                        <Link
                          to={`${linkToSiteSettings}/builds`}
                          variant="SIMPLE"
                        >
                          <MdSettings />
                        </Link>
                      </span>
                    </Tooltip>
                  </span>
                )}
              </div>
              <div css={actionsCss}>
                <Link to={linkToHistory} variant="SIMPLE">
                  {deploysViewText.labels.viewProductionHistory}
                </Link>

                <TriggerBuild
                  siteId={siteId}
                  selectedBranch={productionBranch}
                  orgStatus={siteData.organization?.status}
                  size={`M`}
                  triggerBuildError={triggerBuildError}
                  setTriggerBuildError={setTriggerBuildError}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {triggerBuildError && (
          <React.Fragment>
            <Spacer size={7} />
            {triggerBuildError}
          </React.Fragment>
        )}

        {gatsbyHostingOn && isEligiblePlan && (
          <React.Fragment>
            {latestHostingDeployVersion ? (
              <SiteBuildsPublishedBuild
                siteId={siteId}
                siteData={siteData}
                organizationId={organizationId}
                buildId={latestHostingDeployVersion ?? undefined}
                gatsbyHostingOn={gatsbyHostingOn}
                isEligiblePlan={isEligiblePlan}
                repositoryUrl={repositoryUrl}
              />
            ) : (
              <React.Fragment>
                {flags.updatedCloudNavigation && (
                  <div css={emptyStateWrapperCss}>
                    <EmptyState
                      heading={`No published builds yet`}
                      text={`Your first successful published build will appear here.`}
                      headingAs="h2"
                      graphic={<EmptyStateGraphicAlternative />}
                      variant="BORDERED"
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {!flags.updatedCloudNavigation && (
          <React.Fragment>
            {lastBuild ? (
              <SiteBuildsLastBuild
                siteId={siteId}
                buildData={lastBuild}
                siteData={siteData}
                organizationId={organizationId}
                latestHostingDeployVersion={
                  latestHostingDeployVersion ?? undefined
                }
                gatsbyHostingOn={gatsbyHostingOn}
                onPublish={setBuildToPublish}
                onBuildSucceed={refetchSiteDetails}
                isEligiblePlan={isEligiblePlan}
                repositoryUrl={repositoryUrl}
                publishedBuildPosition={publishedBuildPosition}
                lastBuildPosition={lastBuildPosition}
                linkToHistory={linkToHistory}
                manualHostingDeploysEnabled={
                  manualHostingDeploysEnabled ?? undefined
                }
                onViewBuildQueue={onViewBuildQueue}
              />
            ) : (
              <div css={emptyStateWrapperCss}>
                <EmptyState
                  heading={`No builds yet`}
                  text={`Your latest build will appear here.`}
                  headingAs="h2"
                  graphic={<EmptyStateGraphicAlternative />}
                  variant="BORDERED"
                />
              </div>
            )}
          </React.Fragment>
        )}

        {flags.updatedCloudNavigation && (
          <ProductionBuildsList
            title={
              <div css={buildHistoryTitleWrapperCss}>
                <div
                  css={
                    canSeePublishedBuilds
                      ? buildHistoryTitleRowCss
                      : buildHistoryTitleRowNoPublishedBuildsCss
                  }
                >
                  <Heading as="h2" css={headingCss}>
                    {deploysViewText.headers.buildHistory}
                  </Heading>
                  {!canSeePublishedBuilds ? (
                    <TriggerBuild
                      siteId={siteId}
                      selectedBranch={productionBranch}
                      orgStatus={siteData.organization?.status}
                      size={`M`}
                      triggerBuildError={triggerBuildError}
                      setTriggerBuildError={setTriggerBuildError}
                    />
                  ) : (
                    <React.Fragment>
                      {lastBuild && siteData?.stableBuildURL && (
                        <div css={stableBuildLinkInline}>
                          <LastSuccessBuildMessage
                            buildURL={siteData?.stableBuildURL}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
                {!canSeePublishedBuilds &&
                  lastBuild &&
                  siteData?.stableBuildURL && (
                    <LastSuccessBuildMessage
                      buildURL={siteData?.stableBuildURL}
                    />
                  )}
              </div>
            }
            organizationId={organizationId}
            siteId={siteId}
            branch={productionBranch}
          />
        )}
        {!flags.updatedCloudNavigation &&
          lastBuild &&
          siteData?.stableBuildURL && (
            <div css={stableBuildLink}>
              Latest successful build always at&nbsp;
              <ExternalLink href={siteData?.stableBuildURL} truncate size={1} />
            </div>
          )}
      </div>

      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </React.Fragment>
  )
}

/* styles */

const emptyStateWrapperCss: ThemeCss = theme => ({
  marginTop: theme.space[6],
  marginBottom: theme.space[10],
})

const buildHistoryTitleWrapperCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[4],
})

const buildHistoryTitleRowCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  alignItems: `flex-start`,
  gap: theme.space[2],
  [theme.mediaQueries.desktop]: {
    alignItems: `center`,
    flexDirection: `row`,
  },
})

const buildHistoryTitleRowNoPublishedBuildsCss: ThemeCss = theme => [
  buildHistoryTitleRowCss(theme),
  {
    flexDirection: `row`,
    [theme.mediaQueries.tablet]: {
      alignItems: `flex-start`,
    },
  },
]

const headingCss: ThemeCss = theme => ({
  whiteSpace: `nowrap`,
  fontSize: theme.fontSizes[4],
})

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[5],
  marginTop: theme.space[5],

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr auto`,
  },
})

const buildMetaCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[2],
  alignItems: `flex-start`,
})

const siteMeta: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  flexDirection: `row`,
  flexWrap: `wrap`,

  "& > span": {
    minHeight: theme.space[7],
    marginRight: theme.space[7],
  },
})

const deploysCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    width: `16px`,
    height: `auto`,
    marginRight: theme.space[3],
  },
})

const settingsLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: `.8em`,
    marginLeft: theme.space[2],
    color: theme.colors.grey[40],
  },

  "&:hover svg": {
    color: theme.colors.purple[40],
  },
})

const actionsCss: ThemeCss = theme => ({
  display: `inline-grid`,
  gap: theme.space[6],
  gridTemplateColumns: `auto auto`,
  justifyContent: `end`,

  a: {
    lineHeight: theme.lineHeights.solid,
  },
})

const stableBuildLink: ThemeCss = theme => [
  descriptionCss(theme),
  {
    marginTop: theme.space[7],
    justifyContent: `flex-end`,

    a: {
      fontWeight: theme.fontWeights.body,
    },
  },
]

const stableBuildLinkInline: ThemeCss = theme => [
  descriptionCss(theme),
  {
    margin: 0,
    justifyContent: `flex-end`,
    a: {
      fontWeight: theme.fontWeights.body,
    },
  },
]

const buildActionsCss: ThemeCss = theme => ({
  display: `flex`,
  alignItemes: `center`,
  flexWrap: `wrap`,
})
