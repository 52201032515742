/*
 * These are the components used for "Phase 0" accelerated sourcing
 * and will likely be removed in a future iteration of Data Layer
 * work. These are behind a feature flag and should be removed when
 * that feature flag is no longer in use.
 *
 * https://app.launchdarkly.com/default/production/features/enableMerlinPullRequestsSourcingToggle/targeting
 *
 */

import * as React from "react"
import {
  SplitButton,
  DropdownMenuItem,
  Text,
  Link,
  InProgressIcon,
  Spacer,
} from "gatsby-interface"
import { MdCheck, MdError, MdPending } from "react-icons/md"
import { useFlags } from "@modules/featureFlags"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate" // [sic]
import { getTheme } from "gatsby-interface"
import {
  useAcceleratedSourcingStatusQuery,
  AcceleratedSourcingStatusDocument,
} from "./queries.generated"
import {
  useTriggerMerlinSourcingMutation,
  useTriggerClearCacheMerlinSourcingMutation,
} from "./mutations.generated"
import { MerlinStatus } from "../../graphql/types"

import { SiteDetailsFieldsFragment } from "@modules/site/shared/fragments.generated"

const icons = {
  [MerlinStatus.Idle]: <MdCheck size="16px" />,
  [MerlinStatus.Pending]: <MdPending size="16px" />,
  [MerlinStatus.Cancelled]: <MdError size="16px" />,
  [MerlinStatus.InProgress]: <InProgressIcon size="xxsmall" />,
  [MerlinStatus.Success]: <MdCheck size="16px" />,
  [MerlinStatus.Failed]: <MdError size="16px" />,
}

const messages = {
  [MerlinStatus.InProgress]: "Re-sourcing data",
  [MerlinStatus.Failed]: "Error - please re-source data",
  [MerlinStatus.Failed]: "Cancelled Source Event",
}

const theme = getTheme()

// used in modules/site/details/components/PullRequests
export function AcceleratedSourcingBuildsWidget({
  siteId,
  siteDetails,
}: AcceleratedSourcingBuildsWidgetProps) {
  const { flags } = useFlags()

  if (!flags.enableMerlinPullRequestsSourcingToggle) return null

  const enabled = siteDetails?.merlinPullRequestsSourcingEnabled
  const href = `/dashboard/${siteDetails.organizationId}/sites/${siteId}/settings/builds/#enable-merlin-pull-request-checkbox`

  if (!enabled) {
    return (
      <Text as="div" size="S">
        <Link to={href}>Accelerated Sourcing</Link> disabled
      </Text>
    )
  }

  return (
    <div
      css={theme => ({
        display: "flex",
        alignItems: "center",
        flex: "none",
        gap: theme.space[5],
      })}
    >
      <SourcingStatus siteId={siteId} href={href} />
      <ReSourceButton siteId={siteId} />
    </div>
  )
}

function SourcingStatus({
  siteId,
  siteDetails,
  href,
}: AcceleratedSourcingBuildsWidgetProps & { href: string }) {
  const { data, loading, error } = useAcceleratedSourcingStatusQuery({
    variables: {
      id: siteId,
    },
    pollInterval: 3000,
  })

  if (loading) return null
  if (error) {
    console.error(error)
    return icons.FAILED
  }
  if (!data) return icons.IDLE

  const icon =
    icons[data.merlinStatusForResource?.status] || icons[MerlinStatus.Failed]
  const date = getContextuallyFormatedDate(
    data.merlinStatusForResource?.updatedAt
  )
  const isFailed = [MerlinStatus.Failed, MerlinStatus.Cancelled].includes(
    data.merlinStatusForResource?.status
  )
  const message =
    messages[data.merlinStatusForResource?.status] || `Last updated ${date}`

  return (
    <div>
      <Text as="div" size="S">
        <Link to={href}>Accelerated Sourcing</Link> enabled
      </Text>
      <Text
        as="div"
        size="S"
        style={{
          // note: `variant` prop does not do anything
          color: isFailed ? theme.colors.red[80] : null,
        }}
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
        <Spacer size={3} direction="horizontal" />
        {message}
      </Text>
    </div>
  )
}

function ReSourceButton({ siteId }: AcceleratedSourcingBuildsWidgetProps) {
  const options = {
    variables: {
      siteId,
    },
    refetchQueries: [
      {
        query: AcceleratedSourcingStatusDocument,
        variables: {
          id: siteId,
        },
      },
    ],
  }

  const [
    triggerMerlinSourcingMutation,
    triggerSourcing,
  ] = useTriggerMerlinSourcingMutation(options)

  const [
    triggerMerlinClearCacheSourcingMutation,
    triggerClearCacheSourcing,
  ] = useTriggerClearCacheMerlinSourcingMutation(options)

  return (
    <SplitButton
      size="M"
      buttonLabel="Re-source data"
      dropdownButtonLabel="More options..."
      disabled={triggerSourcing.loading}
      onClick={triggerMerlinSourcingMutation}
    >
      <DropdownMenuItem
        disabled={triggerClearCacheSourcing.loading}
        onSelect={triggerMerlinClearCacheSourcingMutation}
      >
        Clear cache and re-source data
      </DropdownMenuItem>
    </SplitButton>
  )
}

export type AcceleratedSourcingBuildsWidgetProps = {
  siteId: string
  siteDetails: SiteDetailsFieldsFragment
}
