// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type AcceleratedSourcingStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type AcceleratedSourcingStatusQuery = (
  { __typename?: 'Query' }
  & { merlinStatusForResource?: Types.Maybe<(
    { __typename?: 'FullMerlinStatus' }
    & Pick<Types.FullMerlinStatus, 'status' | 'updatedAt'>
  )> }
);


export const AcceleratedSourcingStatusDocument = gql`
    query AcceleratedSourcingStatus($id: UUID!) {
  merlinStatusForResource(resourceId: $id, resourceType: SITE) {
    status
    updatedAt
  }
}
    `;

/**
 * __useAcceleratedSourcingStatusQuery__
 *
 * To run a query within a React component, call `useAcceleratedSourcingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceleratedSourcingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceleratedSourcingStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceleratedSourcingStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcceleratedSourcingStatusQuery, AcceleratedSourcingStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<AcceleratedSourcingStatusQuery, AcceleratedSourcingStatusQueryVariables>(AcceleratedSourcingStatusDocument, baseOptions);
      }
export function useAcceleratedSourcingStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcceleratedSourcingStatusQuery, AcceleratedSourcingStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AcceleratedSourcingStatusQuery, AcceleratedSourcingStatusQueryVariables>(AcceleratedSourcingStatusDocument, baseOptions);
        }
export type AcceleratedSourcingStatusQueryHookResult = ReturnType<typeof useAcceleratedSourcingStatusQuery>;
export type AcceleratedSourcingStatusLazyQueryHookResult = ReturnType<typeof useAcceleratedSourcingStatusLazyQuery>;
export type AcceleratedSourcingStatusQueryResult = ApolloReactCommon.QueryResult<AcceleratedSourcingStatusQuery, AcceleratedSourcingStatusQueryVariables>;
