import * as React from "react"
import { ThemeCss } from "gatsby-interface"

import {
  SettingsCardContent,
  SettingsCardDescription,
} from "@modules/site/settings"
import { SiteSettingsSection, SiteSettingsSectionLabel } from "../constants"
import SiteSettingsSectionCard from "./SiteSettingsSectionCard"
import CopyUrl from "../../../ui/components/CopyUrl"
import { getContentLoaderUrl } from "@modules/site/shared/helpers"
import { HelpLink } from "@modules/ui/components/HelpLink"

const sharedContentCss: ThemeCss = theme => ({
  maxWidth: "100%",
  marginTop: theme.space[5],
})

export default function ContentLoaderSection({ siteId }: { siteId: string }) {
  const contentLoaderURL = getContentLoaderUrl(siteId)

  return (
    <SiteSettingsSectionCard
      css={{ display: "block" }}
      id={SiteSettingsSection.ContentLoader}
      renderTitle={() => (
        <React.Fragment>
          {SiteSettingsSectionLabel[SiteSettingsSection.ContentLoader]}
          {` `}
          <HelpLink
            href={`${window.location.origin}/docs/conceptual/content-sync/`}
            aria-label="Content Sync docs"
            css={theme => ({ marginLeft: theme.space[3] })}
          ></HelpLink>
        </React.Fragment>
      )}
    >
      <SettingsCardContent css={sharedContentCss}>
        <SettingsCardDescription data-cy={`content-loader-card-description`}>
          Use this URL inside supported CMSs to ensure content editors are
          routed to the correct page on Gatsby Cloud.
        </SettingsCardDescription>
        <CopyUrl url={contentLoaderURL} />
      </SettingsCardContent>
    </SiteSettingsSectionCard>
  )
}
