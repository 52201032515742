import { IntegrationsIcon, IconProps } from "gatsby-interface"
import { DataLayerSection, DataLayersSectionLabel } from "../constants"
import { getSiteDetailsTabBasePath } from "../../details/utils"
import { SiteDetailsTab } from "../../details/constants"
import getSideNavOptions from "../../../layout/utils/getSideNavOptions"
import { ImFilesEmpty } from "react-icons/im"
import * as React from "react"
import { GiSoundWaves } from "react-icons/gi"

export const dataLayerSections = [
  {
    id: DataLayerSection.Overview,
    Icon: IntegrationsIcon,
  },
  {
    id: DataLayerSection.Webhooks,
    Icon: WebhookIcon,
  },
]
export type dataLayerSectionsProp = {
  id: string
  Icon: React.ReactFragment
}

export type useDataLayerNavigationProps = {
  organizationId: string
  siteId: string
  location: string
}

export function useDataLayerNavigation({
  siteId,
  organizationId,
  location,
}: useDataLayerNavigationProps) {
  return getSideNavOptions({
    sections: dataLayerSections as Array<dataLayerSectionsProp>,
    sectionLabels: DataLayersSectionLabel,
    getLink: (sectionId: string) =>
      `${getSiteDetailsTabBasePath(
        SiteDetailsTab.DataLayer,
        siteId,
        organizationId
      )}/${sectionId}`,
    location,
  })
}

function LogIcon(props: IconProps) {
  return <ImFilesEmpty {...props} size=".9em" />
}

function WebhookIcon(props: IconProps) {
  return <GiSoundWaves {...props} size="1em" />
}
