import * as React from "react"
import { ThemeCss, Link, Spacer } from "gatsby-interface"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { deploysView as text, ui as uiText } from "@modules/locales/default"

export type LastSuccessBuildMessageProps = {
  buildURL?: string
}

export function LastSuccessBuildMessage({
  buildURL,
}: LastSuccessBuildMessageProps) {
  return (
    <p css={lastSuccessBuildMessageCss}>
      <span css={{ whiteSpace: `nowrap` }}>
        {text.messages.latestSuccessfulBuildAlwaysAt}
      </span>{" "}
      <ExternalLink href={buildURL} truncate size={1} />
    </p>
  )
}

type AutomaticDeploysStatusProps = {
  enabled?: boolean | null
  siteId: string
  organizationId: string
}

export function AutomaticDeploysStatus({
  siteId,
  organizationId,
  enabled,
}: AutomaticDeploysStatusProps) {
  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )
  return (
    <span css={automaticDeploysCss}>
      <Link to={`${linkToSiteSettings}/builds#build-features`}>
        {text.labels.automaticDeploys}
      </Link>
      <span>{enabled ? uiText.labels.enabled : uiText.labels.disabled}</span>
    </span>
  )
}

/* styles */

const lastSuccessBuildMessageCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],
  margin: 0,
})

const automaticDeploysCss: ThemeCss = theme => ({
  display: `inline-flex`,
  fontSize: theme.fontSizes[1],
  marginRight: theme.space[5],
  marginTop: theme.space[3],
  marginBottom: theme.space[3],
  lineHeight: theme.lineHeights.solid,
  alignItems: `center`,

  span: {
    textTransform: `lowercase`,
    marginLeft: theme.space[3],
  },
})
