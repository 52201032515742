/* tslint:disable:@typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { WindowLocation } from "@gatsbyjs/reach-router"
import { Heading, Spacer, ThemeCss, Text } from "gatsby-interface"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import {
  getPathToBuildDetails,
  getPathToBuildsForPR,
} from "@modules/site/details/utils"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import Loading from "@modules/ui/components/Loading"
import { deploysView as deploysViewText } from "@modules/locales/default.js"
import { SourceControlProvider } from "@modules/graphql/types"
import { usePullRequests } from "../hooks/useDeploys"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { useDebouncedInput } from "@modules/toolkit/hooks/useDebouncedInput"
import useSiteDetailsData from "@modules/site/shared/hooks/useSiteDetailsData"
import { useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"
import { SiteBuildsProduction } from "./SiteBuildsProduction"
import { useRollbacksAndManualDeploysAvailable } from "@modules/organization/shared/hooks/useRollbacksAndManualDeploysAvailable"
import { useFlags } from "@modules/featureFlags"
import { hasSourcePullRequestPermission } from "@modules/site/shared/helpers"
import { DeploysPaneContent } from "../layouts/DeploysPaneLayouts"
import { FormattedMessage } from "@modules/locales"
import Searchbar from "@modules/site/shared/components/Searchbar"
import { PullRequestPermission } from "./PullRequestPermission"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { Waypoint } from "react-waypoint"
import { NoDeploys } from "./NoDeploys"

const headerCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  flexBasis: `100%`,
  fontSize: theme.fontSizes[4],
})

const subHeaderCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  fontSize: theme.fontSizes[1],
})

export type DeploysProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  onViewBuildQueueClick?: () => void
}

export function Deploys({
  organizationId,
  siteId,
  onViewBuildQueueClick,
}: DeploysProps) {
  const { flags } = useFlags()
  const [searchValue, debouncedValue, setSearchValue, isDebouncing] =
    useDebouncedInput()

  const { search } = useLocation()
  const { newsite: isNewSite } = qs.parse(search)

  const siteDetails = useLocalSiteDetailsForBuilds(siteId)
  const productionBranch = siteDetails.branch
  const repositoryUrl = siteDetails.repository?.url
  const isEligiblePlan =
    useRollbacksAndManualDeploysAvailable(organizationId).result

  const lastBuildForBranch = siteDetails?.latestBuild

  const [deploys, { loading: loadingDeploys, loadingMore, loadMore }] =
    usePullRequests(siteId, debouncedValue)

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  const [, { startPolling, stopPolling, refetch: refetchSiteDetails }] =
    useSiteDetailsData(siteId, {
      shouldSkip: !isNewSite,
    })

  if (isNewSite) {
    startPolling(3000)
  }

  if (siteDetails?.stableBuildURL) {
    stopPolling()
  }

  const hasPullRequestPermissions = hasSourcePullRequestPermission(
    siteDetails?.sourceOrganization?.permissions || []
  )

  const showNoDeploys =
    hasPullRequestPermissions &&
    deploys.length === 0 &&
    !searchValue &&
    !isDebouncing

  const showEmptySearchResponse =
    hasPullRequestPermissions &&
    deploys.length === 0 &&
    searchValue &&
    !isDebouncing

  return flags.updatedCloudNavigation ? (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        {loadingDeploys && (
          <React.Fragment>
            <Loading message="Loading builds..." delay={1000} variant="baby" />
            <Spacer size={5} />
          </React.Fragment>
        )}

        <SiteBuildsProduction
          organizationId={organizationId}
          siteId={siteId}
          siteData={siteDetails}
          lastBuild={lastBuildForBranch}
          repositoryUrl={repositoryUrl}
          isEligiblePlan={isEligiblePlan}
          refetchSiteDetails={refetchSiteDetails}
          onViewBuildQueue={onViewBuildQueueClick}
        />
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  ) : (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        {loadingDeploys && (
          <React.Fragment>
            <Loading message="Loading builds..." delay={1000} variant="baby" />
            <Spacer size={5} />
          </React.Fragment>
        )}

        <SiteBuildsProduction
          organizationId={organizationId}
          siteId={siteId}
          siteData={siteDetails}
          lastBuild={lastBuildForBranch}
          repositoryUrl={repositoryUrl}
          isEligiblePlan={isEligiblePlan}
          refetchSiteDetails={refetchSiteDetails}
          onViewBuildQueue={onViewBuildQueueClick}
        />

        <Spacer size={9} />
        <DeploysPaneContent>
          <Heading as="h2" id="deploys" css={headerCss}>
            {siteDetails?.repository?.provider === SourceControlProvider.Gitlab
              ? deploysViewText.headers.mergeRequests
              : deploysViewText.headers.pullRequests}
          </Heading>

          <Spacer size={3} />

          <Text css={subHeaderCss}>
            <FormattedMessage<"productionBranch", "strong">
              message={
                siteDetails?.repository?.provider ===
                SourceControlProvider.Gitlab
                  ? deploysViewText.messages.mergeRequestBuilds
                  : deploysViewText.messages.pullRequestBuilds
              }
              values={{
                productionBranch,
              }}
              tags={{
                strong: function (content) {
                  return <strong>{content}</strong>
                },
              }}
            />
          </Text>
          <Spacer size={7} />

          <Searchbar
            ariaLabel={
              siteDetails?.repository?.provider === SourceControlProvider.Gitlab
                ? deploysViewText.labels.searchInputGitlab
                : deploysViewText.labels.searchInputGithub
            }
            placeholder={
              siteDetails?.repository?.provider === SourceControlProvider.Gitlab
                ? deploysViewText.labels.searchInputPlaceholderGitlab
                : deploysViewText.labels.searchInputPlaceholderGithub
            }
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value)
            }}
          />

          {showEmptySearchResponse && (
            <React.Fragment>
              <Spacer size={3} />
              <Text>{deploysViewText.messages.noSearchResults}</Text>
            </React.Fragment>
          )}

          <Spacer size={5} />

          {showNoDeploys && <NoDeploys productionBranch={productionBranch} />}
          {!hasPullRequestPermissions && (
            <PullRequestPermission
              permissionsUrl={siteDetails?.sourceOrganization?.permissionsURL}
            />
          )}

          <Feed
            loading={loadingDeploys || loadingMore}
            labelledBy="deploys"
            count={deploys.length}
          >
            <div>
              {deploys.map((build, index) => (
                <div key={`deploy-${build.id}`}>
                  <FeedItem
                    labelledBy={`deploy-${build.id}`}
                    describedBy={`deploy-status-${build.id}`}
                    position={index + 1}
                    id={build.id}
                  >
                    <BuildCard
                      id={`deploy-${build.id}`}
                      siteId={siteId}
                      organizationId={organizationId}
                      pullRequestId={build?.pullRequest?.id}
                      title={build.pullRequest?.title || build.branch || ``}
                      buildId={build.id}
                      status={build.buildStatus}
                      createdAt={build.createdAt}
                      startedAt={build.startedAt}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      duration={build.duration || undefined}
                      endedAt={build.endedAt}
                      branch={build.branch || ``}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      commit={build.commit || undefined}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      runnerType={build.runnerType!}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      buildType={build.buildType!}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      source={build.source || undefined}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      author={build.author || undefined}
                      isProductionBranch={productionBranch === build.branch}
                      viewDetailsHref={getPathToBuildDetails(
                        build.id,
                        siteId,
                        organizationId
                      )}
                      viewAllBuildsHref={getPathToBuildsForPR(
                        build.pullRequest?.id,
                        siteId,
                        organizationId
                      )}
                      deployStartedAt={build?.deployStartedAt}
                      deployEndedAt={build?.deployEndedAt}
                      repositoryUrl={repositoryUrl}
                      routeMetadata={build?.routeMetadata || undefined}
                      buildMetadata={build?.metadata || undefined}
                      onViewBuildQueue={onViewBuildQueueClick}
                      buildUrl={build.url}
                    />
                  </FeedItem>
                  <Spacer size={5} />
                </div>
              ))}
            </div>
          </Feed>

          {loadingMore && (
            <div css={{ textAlign: "center" }}>
              <Loading variant="baby" message="Loading deploys..." />
            </div>
          )}
          <Waypoint onEnter={loadMore} />
        </DeploysPaneContent>
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}
