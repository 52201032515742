import { Link, Notification, ThemeCss } from "gatsby-interface"
import { MdArrowForward, MdInfo } from "react-icons/md"

const contentCss: ThemeCss = () => ({
  margin: 0,
  maxWidth: "860px",
})

const boldCss: ThemeCss = theme => ({
  fontWeight: theme.fontWeights.bold,
})

const linkIconCss: ThemeCss = theme => ({
  marginLeft: theme.space[1],
})

export function DisabledDataBuildsBanner() {
  return (
    <Notification
      tone={"DANGER"}
      variant={"SECONDARY"}
      Icon={MdInfo}
      content={
        <p css={contentCss}>
          <span css={boldCss}>
            Data update invoked builds for this site are currently suspended.{" "}
          </span>
          <span>
            To re-enable them push a code update that resolves the issue, or
            adjust the content that causes the error and trigger a new build.{" "}
          </span>

          <Link
            variant="SIMPLE"
            href="https://support.gatsbyjs.com/hc/en-us/articles/9905462306067-Suspended-Data-Update-Builds"
          >
            Read the docs for more information
            <MdArrowForward css={linkIconCss} />
          </Link>
        </p>
      }
    />
  )
}
