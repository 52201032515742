// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type TriggerMerlinSourcingMutationVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
}>;


export type TriggerMerlinSourcingMutation = (
  { __typename?: 'Mutation' }
  & { triggerMerlinSourcing?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);

export type TriggerClearCacheMerlinSourcingMutationVariables = Types.Exact<{
  siteId: Types.Scalars['UUID'];
}>;


export type TriggerClearCacheMerlinSourcingMutation = (
  { __typename?: 'Mutation' }
  & { triggerMerlinSourcing?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);


export const TriggerMerlinSourcingDocument = gql`
    mutation triggerMerlinSourcing($siteId: UUID!) {
  triggerMerlinSourcing(siteId: $siteId) {
    success
    message
  }
}
    `;
export type TriggerMerlinSourcingMutationFn = ApolloReactCommon.MutationFunction<TriggerMerlinSourcingMutation, TriggerMerlinSourcingMutationVariables>;

/**
 * __useTriggerMerlinSourcingMutation__
 *
 * To run a mutation, you first call `useTriggerMerlinSourcingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerMerlinSourcingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerMerlinSourcingMutation, { data, loading, error }] = useTriggerMerlinSourcingMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useTriggerMerlinSourcingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerMerlinSourcingMutation, TriggerMerlinSourcingMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerMerlinSourcingMutation, TriggerMerlinSourcingMutationVariables>(TriggerMerlinSourcingDocument, baseOptions);
      }
export type TriggerMerlinSourcingMutationHookResult = ReturnType<typeof useTriggerMerlinSourcingMutation>;
export type TriggerMerlinSourcingMutationResult = ApolloReactCommon.MutationResult<TriggerMerlinSourcingMutation>;
export type TriggerMerlinSourcingMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerMerlinSourcingMutation, TriggerMerlinSourcingMutationVariables>;
export const TriggerClearCacheMerlinSourcingDocument = gql`
    mutation triggerClearCacheMerlinSourcing($siteId: UUID!) {
  triggerMerlinSourcing(siteId: $siteId, deleteCache: true) {
    success
    message
  }
}
    `;
export type TriggerClearCacheMerlinSourcingMutationFn = ApolloReactCommon.MutationFunction<TriggerClearCacheMerlinSourcingMutation, TriggerClearCacheMerlinSourcingMutationVariables>;

/**
 * __useTriggerClearCacheMerlinSourcingMutation__
 *
 * To run a mutation, you first call `useTriggerClearCacheMerlinSourcingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerClearCacheMerlinSourcingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerClearCacheMerlinSourcingMutation, { data, loading, error }] = useTriggerClearCacheMerlinSourcingMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useTriggerClearCacheMerlinSourcingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerClearCacheMerlinSourcingMutation, TriggerClearCacheMerlinSourcingMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerClearCacheMerlinSourcingMutation, TriggerClearCacheMerlinSourcingMutationVariables>(TriggerClearCacheMerlinSourcingDocument, baseOptions);
      }
export type TriggerClearCacheMerlinSourcingMutationHookResult = ReturnType<typeof useTriggerClearCacheMerlinSourcingMutation>;
export type TriggerClearCacheMerlinSourcingMutationResult = ApolloReactCommon.MutationResult<TriggerClearCacheMerlinSourcingMutation>;
export type TriggerClearCacheMerlinSourcingMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerClearCacheMerlinSourcingMutation, TriggerClearCacheMerlinSourcingMutationVariables>;
