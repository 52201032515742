import * as React from "react"
import {
  PlatformLimitSummary,
  PlatformLimitSummaryName,
  PlatformUsage,
} from "@modules/graphql/types"
import { PlatformLimitLabels } from "@modules/organization/shared/constants"
import { ThemeCss } from "gatsby-interface"

type PlatformLimitSummaryMessage = Pick<
  PlatformLimitSummary,
  "overage" | "name"
> & { workspace: Omit<PlatformUsage, "resourceId" | "resourceType"> }

export function getPlatformLimitOveragesMessage(
  summaries: PlatformLimitSummaryMessage[]
) {
  const overages = summaries
    .filter(item => item.overage)
    .map(item => {
      return {
        name: item.name,
        value: `${item.workspace?.percentageOfLimit}%`,
      }
    })

  return overages.reduce<React.ReactNode>((acc, cur, idx, arr) => {
    let connector

    if (idx === 0) {
      connector = ``
    } else if (idx === arr.length - 1) {
      connector = ` and `
    } else {
      connector = `, `
    }

    const name = PlatformLimitLabels[cur.name as PlatformLimitSummaryName]

    if (!name) {
      return <span>{acc}</span>
    }

    const formattedName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`

    return (
      <React.Fragment>
        {acc}
        {connector}
        <b css={testCss}>{cur.value}</b> of its{" "}
        <em css={nameCss}>{formattedName}</em>
      </React.Fragment>
    )
  }, ``)
}

/* styles */

const nameCss: ThemeCss = theme => ({
  fontStyle: `normal`,
  fontWeight: theme.fontWeights.semiBold,
})

const testCss: ThemeCss = theme => ({
  fontWeight: theme.fontWeights.bold,
})
