import {
  SettingsCard,
  SettingsCardContent,
  SettingsCardTitle,
} from "@modules/site/settings"
import CmsIntegrations from "@modules/site/settings/components/CmsIntegrations"
import { Link, Spacer } from "gatsby-interface"
import * as React from "react"
import { MdArrowForward } from "react-icons/md"

export type DataLayerOverviewProps = {
  siteId: string
  orgSiteName: string
  permissionToEdit: boolean
}
export function DataLayerOverview({
  siteId,
  orgSiteName,
  permissionToEdit,
}: DataLayerOverviewProps) {
  return (
    <div>
      <SettingsCard>
        <SettingsCardTitle>Data Layer</SettingsCardTitle>

        {/* TODO: extract this in a aseparate component with functionality. */}
        {/* <SplitButton
          buttonLabel={"Open GraphiQL"}
          dropdownButtonLabel={"data-layer-cache-clear"}
          onClick={() => { }}
          size={`M`}
          disabled={false}
        >
          <DropdownMenuItem onSelect={() => { }}>
            {"Clear cache and restart"}
          </DropdownMenuItem>
        </SplitButton> */}

        <SettingsCardContent>
          <React.Fragment>
            Manage your GraphQL data layer.
            <Spacer direction="horizontal" size={2} />
            <Link
              href={`https://www.gatsbyjs.com/docs/reference/graphql-data-layer/`}
              variant="SIMPLE"
            >
              Read docs <MdArrowForward />
            </Link>
          </React.Fragment>
        </SettingsCardContent>
      </SettingsCard>
      <Spacer size={10} />

      <CmsIntegrations
        siteId={siteId}
        orgSiteName={orgSiteName}
        permissionToEdit={permissionToEdit}
      />
    </div>
  )
}
